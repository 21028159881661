export default function translateModule(moduleName: string): string {
  const moduleMapping: Record<string, string> = {
    panel: 'Painel',
    realties: 'Imóveis',
    messages: 'Leads de Imóveis',
    partnershipLeads: 'Leads de Parcerias',
    clients: 'Cadastros',
    companies: 'Empresas',
    brokers: 'Corretores',
    agencies: 'Agências',
    crms: 'CRMs',
    persons: 'Pessoas',
    campaigns: 'Campanhas',
    leadAdsForms: 'Formulários',
    leadsSubmission: 'Envio de leads',
    shortLinks: 'Link Curto',
    services: 'Serviços',
    reports: 'Relatórios',
    highlights: 'Destaques',
    homeApto: 'Home do Apto',
    attributes: 'Atributos',
    locations: 'Regiões',
    jobPositions: 'Vagas',
    railStation: 'Estações ferroviárias',
    frequentlyAskedQuestions: 'Aten. ao cliente',
    propertyIndexes: 'Índices',
    institutionals: 'Institucional',
    savedSearches: 'Vince te avisa',
    feeds: 'Feeds',
    financialInstitutions: 'Taxas de financiamento',
    configs: 'Configurações',
    customization: 'Personalização',
    vince: 'Vince',
    users: 'Usuários',
    debug: 'Depurador',
    logs: 'Logs',
    cortex: 'Cortex',
    coupons: 'Cupons',
    history_limit: 'Limite para consultas de Leads',
  }

  return moduleMapping[moduleName] ?? moduleName
}

export function translateModuleSingular(moduleName: string): string {
  const moduleSingularMapping: Record<string, string> = {
    realties: 'Imóvel',
    messages: 'Lead',
    clients: 'Cadastros',
    companies: 'Empresa',
    brokers: 'Corretor',
    agencies: 'Agência',
    crms: 'CRM',
    persons: 'Pessoa',
    campaigns: 'Campanha',
    attributes: 'Atributo',
    jobPositions: 'Vaga',
    railStation: 'Estação Ferroviária',
    propertyIndexes: 'Índice',
    users: 'Usuário',
  }

  return moduleSingularMapping[moduleName] ?? moduleName
}

export function translateModuleLink(moduleName: string): string {
  const moduleLinkMapping: Record<string, string> = {
    leads: 'messages',
    'partnership-leads': 'partnershipLeads',
    'lead-ads-forms': 'leadAdsForms',
    'leads-submission': 'leadsSubmission',
    'short-links': 'shortLinks',
    'home-apto': 'homeApto',
    jobs: 'jobPositions',
    'rail-station': 'railStation',
    'customer-faq': 'frequentlyAskedQuestions',
    'property-indexes': 'propertyIndexes',
    'financing-fees': 'financialInstitutions',
    'saved-searches': 'savedSearches',
  }

  return moduleLinkMapping[moduleName] ?? moduleName
}
