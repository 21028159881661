import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { pages } from 'components/global/Modules/pages'
import LinkCustom from 'components/LinkCustom'
import translateModule from 'utils/functions/translateModuleName'
import useStore from './useStore'
import * as S from './styles'
import { Menu, Close } from '@mui/icons-material'
import { useAuth } from 'context/Auth/hook'

export const SubSidebar = () => {
  const { user } = useAuth()

  const { pathname } = useLocation()
  const [, baseUrl] = pathname.split('/')

  const display = useStore((state) => state.display)
  const open = useStore((state) => state.open)
  const toggleDisplay = useStore((state) => state.toggleDisplay)
  const toggle = useStore((state) => state.toggle)

  const [subSideItems, setSubSideItems] = useState([])

  const pagesWithInternals = pages
    .flat(1)
    .filter((pageGroup) => pageGroup.internals)

  const subMenuPages =
    pagesWithInternals[
      pagesWithInternals
        .map(({ internals }) =>
          internals.map(({ link }) => link === baseUrl).some(Boolean),
        )
        .indexOf(true)
    ]

  useEffect(() => {
    const rawItems = []
    subMenuPages?.internals.forEach((page) => {
      if (user.permissions[page.module] > 0) {
        rawItems.push({
          name: translateModule(page.module),
          to: page.link,
        })
      }
    })

    toggleDisplay(!!rawItems.length)
    setSubSideItems(rawItems)
  }, [baseUrl])

  if (!display) return

  return (
    <S.SubSidebarContainer className="subSidebar" $open={open}>
      <S.MobileToggle>
        <IconButton size="small" color="standard" onClick={toggle}>
          <Close className="close" />
          <Menu className="open" />
        </IconButton>
      </S.MobileToggle>

      <S.SubSidebarContent>
        {subSideItems.map((page, index) => (
          <S.SidebarItem key={index} selected={baseUrl === page.to}>
            <LinkCustom to={`/${page.to}`}>
              <S.Wrapper onClick={toggle}>{page.name}</S.Wrapper>
            </LinkCustom>
          </S.SidebarItem>
        ))}
      </S.SubSidebarContent>
    </S.SubSidebarContainer>
  )
}
