import { ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'

export const ModulesSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.sidebar.scrollbar};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${theme.sidebar.scrollbar}9;
    }
  `}
`

export const Text = styled.div`
  color: ${({ theme }) => theme.sidebar.text};
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SidebarItem = styled(ListItemButton)`
  ${({ theme, selected }) => css`
    .sidebar-icon,
    ${Wrapper} > svg {
      vertical-align: middle;
      color: ${selected ? theme.colors.primary : theme.colors.standardDark};
      font-size: ${theme.font.sizes.xlarge};
    }

    &.MuiListItemButton-root {
      padding: 0;
      border-radius: 5px;
      overflow: hidden;
    }

    ${selected &&
    css`
      ${Text} {
        color: ${theme.colors.primary};
      }
    `}
  `}
`

export const Wrapper = styled.button`
  border: 0;
  font-size: inherit;
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px 23px;

  @media (min-width: 769px) {
    pointer-events: none;
  }
`
